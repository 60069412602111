import BigTitle from './BigTitleHome';


        
const Hero = () => {


return (

<div>
  

<BigTitle title1='KETAN PATEL' title2='Hi! Im a London based UX/UI Designer.' title3='I love designing simple solutions to complex problems.' />


    
</div>
)


}


export default Hero;