import { AppShell} from '@mantine/core';
import MainMenu from './ActiveLink';

const Navbar = () => {


  return (


 <div>

<AppShell.Navbar className='theme90' >


<MainMenu />


</AppShell.Navbar>

</div>

  );
};




export default Navbar;